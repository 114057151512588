var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PainelCLAV',{attrs:{"titulo":"Pedidos Novos","infoHeader":"Pedidos Novos"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-badge',{attrs:{"color":"red","overlap":"","offset-x":"9","offset-y":"20"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(" "+_vm._s(_vm.pedidos.length)+" ")]},proxy:true}])},[_c('unicon',{staticClass:"mt-3",attrs:{"name":"pedido-novo-icon","width":"20","height":"20","viewBox":"0 0 20.71 20.709","fill":"#ffffff"}})],1)]},proxy:true},{key:"conteudo",fn:function(){return [_c('v-row',[_c('v-col',[_c('div',{staticClass:"info-content pa-4"},[_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mt-n2 mb-3 mx-6 font-weight-medium",attrs:{"append-icon":"search","label":"Procurar pedido","text":"","single-line":"","hide-details":"","clearable":"","color":"blue darken-3"},model:{value:(_vm.procurar),callback:function ($$v) {_vm.procurar=$$v},expression:"procurar"}},on))]}}])},[_c('span',[_vm._v(" Procurar pedido")])]),_c('v-data-table',{staticClass:"content-table",attrs:{"headers":_vm.headers,"items":_vm.dadosTabela,"search":_vm.procurar,"sortDesc":"","sort-by":"data","custom-sort":_vm.ordenaTabela,"footer-props":_vm.footer_props,"page":_vm.paginaTabela},on:{"update:search":function($event){_vm.procurar=$event},"update:page":function($event){_vm.paginaTabela=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"font-weight-medium my-3",attrs:{"value":true,"color":"error","icon":"warning","id":"alerta-erro"}},[_vm._v("Não existem pedidos neste estado. ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"font-weight-medium my-3",attrs:{"value":true,"color":"error","icon":"warning","id":"alerta-erro"}},[_vm._v("Não foram encontrados resultados para \""+_vm._s(_vm.procurar)+"\".")])]},proxy:true},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}},{key:"headerCell",fn:function(props){return [_c('span',{staticStyle:{"color":"blue"}},[_vm._v(" "+_vm._s(props.header.text)+" ")])]}},{key:"item.tarefa",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":""},on:{"click":function($event){return _vm.showPedido(item)}}},on),[_c('unicon',{attrs:{"name":"look-icon","width":"25","height":"25","viewBox":"0 0 20.71 15.574","fill":"#0D47A1"}})],1)]}}],null,true)},[_c('span',[_vm._v("Ver Pedido")])]),(_vm.temPermissaoDistribuir())?_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":""},on:{"click":function($event){return _vm.distribuiPedido(item)}}},on),[_c('unicon',{attrs:{"name":"participacao-icon","width":"25","height":"25","viewBox":"0 0 20.71 17.678","fill":"#0D47A1"}})],1)]}}],null,true)},[_c('span',[_vm._v("Distribuir Pedido")])]):_vm._e(),(_vm.temPermissaoDevolver())?_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":""},on:{"click":function($event){return _vm.devolverPedido(item)}}},on),[_c('unicon',{attrs:{"name":"devolver-icon","width":"25","height":"25","viewBox":"0 0 20.71 12.943","fill":"#0D47A1"}})],1)]}}],null,true)},[_c('span',[_vm._v("Devolver Pedido")])]):_vm._e()]}},{key:"pageText",fn:function(props){return [_vm._v(" Pedidos "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}}],null,true)})],1)])],1),_c('v-dialog',{attrs:{"width":"60%","persistent":""},model:{value:(_vm.devolverPedidoDialog),callback:function ($$v) {_vm.devolverPedidoDialog=$$v},expression:"devolverPedidoDialog"}},[_c('DevolverPedido',{on:{"fecharDialog":function($event){return _vm.fecharDialog()},"devolverPedido":function($event){return _vm.despacharPedido($event)}}})],1),_c('v-dialog',{attrs:{"width":"60%","persistent":""},model:{value:(_vm.erroDialog.visivel),callback:function ($$v) {_vm.$set(_vm.erroDialog, "visivel", $$v)},expression:"erroDialog.visivel"}},[_c('ErroDialog',{attrs:{"erros":_vm.erroDialog.mensagem,"uri":"/"}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }